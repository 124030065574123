"use strict";

import {Foundation} from 'foundation-sites/dist/js/plugins/foundation.core';
import {Triggers} from 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import {Abide} from 'foundation-sites/dist/js/plugins/foundation.abide';
import {Accordion} from 'foundation-sites/dist/js/plugins/foundation.accordion';
import {DropdownMenu} from 'foundation-sites/dist/js/plugins/foundation.dropdownMenu';
import {Orbit} from 'foundation-sites/dist/js/plugins/foundation.orbit';
import {Reveal} from 'foundation-sites/dist/js/plugins/foundation.reveal';
import {Tabs} from 'foundation-sites/dist/js/plugins/foundation.tabs';
import {Tooltip} from 'foundation-sites/dist/js/plugins/foundation.tooltip';
import $ from 'jquery';

window.jQuery = $; // hacky workaround for foundation-datepicker
require("foundation-datepicker");
require("select2");
require("../utils/jquery-csrf");
import * as Sentry from "@sentry/browser";

$(function () {
  for (let key of ["Facebook", "FB_IAB/FB4A", "HeyTapBrowser", "Instagram"]) {
    if (navigator.userAgent.includes(key)) {
      return;
    }
  }
  const { options, user } = JSON.parse($("#sentry-config").text());
  options.ignoreErrors = [
    // ignore errors whose messages contain these strings or match these regular expressions
    // see https://docs.sentry.io/clients/javascript/config/

    // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
    "ResizeObserver loop limit exceeded",

    // https://gist.github.com/pioug/b006c983538538066ea871d299d8e8bc
    /^No error$/,
    "__show__deepen",
    "_avast_submit",
    "Access is denied",
    "anonymous function: captureException",
    "Blocked a frame with origin",
    "console is not defined",
    "cordova",
    "DataCloneError",
    "Error: AccessDeny",
    "event is not defined",
    "feedConf",
    "ibFindAllVideos",
    "myGloFrameList",
    "SecurityError",
    "MyIPhoneApp",
    "snapchat.com",
    "vid_mate_check is not defined",
    "win.document.body",
    "window._sharedData.entry_data",
    "ztePageScrollModule",
  ];
  options.denyUrls = [
    // Copied from https://gist.github.com/impressiver/5092952, which is old
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ];
  Sentry.init(options);
  Sentry.setUser(user);
});

// init all foundation components on page
Foundation.addToJquery($);
Triggers.init(jQuery, Foundation); // https://github.com/foundation/foundation-sites/issues/7386#issuecomment-337140379
Foundation.plugin(Abide, "Abide");
Foundation.plugin(Accordion, "Accordion");
Foundation.plugin(DropdownMenu, "DropdownMenu");
Foundation.plugin(Orbit, "Orbit");
Foundation.plugin(Reveal, "Reveal");
Foundation.plugin(Tabs, "Tabs");
Foundation.plugin(Tooltip, "Tooltip");

// ---------------------------------------------------------------------------------------------------------------------
// Custom Abide Validators:
const intlPhoneValidator = (el, required, parent) => {
  try {
    const intlPhoneInputControl = el[0].cntrl

    if (!intlPhoneInputControl) {
      return true
    }

    const value = intlPhoneInputControl.getNumber()
    const isValid = intlPhoneInputControl.isValidNumber()

    if (intlPhoneInputControl.hiddenInput) {
      intlPhoneInputControl.hiddenInput.value = value
    }

    return isValid
  } catch (error) {
    console.error(error)
  }

  // If we fail to interact with the input logic we don't want to block the form.
  return true
}

Foundation.Abide.defaults.validators['intl_phone'] = intlPhoneValidator
// ---------------------------------------------------------------------------------------------------------------------

$(document).foundation();

// resize orbit sliders in inactive tabs
$(".tabs").on("change.zf.tabs", () => {
  $(window).trigger("resize");
});

// initialize select2
function matchWordStart(params, data) {
  const term = params.term || "";
  const firstMatchPosition = data.text.toUpperCase().indexOf(term.toUpperCase());

  if (firstMatchPosition === -1)
    return false;

  const previousCharacter = data.text[firstMatchPosition - 1];

  if (! [undefined, ' ', '/', '('].includes(previousCharacter))
    return false;

  return data;
}
$.fn.select2.defaults.set("matcher", matchWordStart);
$.fn.select2.defaults.set("theme", "mm");
$(() => {
  // setting width to 100% appears to make the select2 container responsive
  // as opposed to trying to stick to the width of the initial input box
  $("[data-use-select2]").select2({width: '100%'});

  // Keep the select2 multiselect dropdown from opening when you
  // remove a selection. The internet provided this solution because
  // many people are annoyed with it this behavior.
  $("[data-use-select2]").on("select2:unselecting", (evt) => {
    if (!evt.params.args.originalEvent) return;
    evt.params.args.originalEvent.stopPropagation();
  });

  //  Select2 Doc: https://select2.org/programmatic-control/retrieving-selections
  //  This logic is from SO: https://stackoverflow.com/a/67691578
  //  Multi-select input BECOMES the search field; wherein a single-select prepends the search in results (on focus).
  $(document).on('select2:open', (e) => {
    const selectId = e.target.id
    //  Locate the search__field element that is selectable once the input has been focused/results render
    $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
        key,
        value,
    ){
        //  Focus the "search field"
        value.focus();
    })
  })
});

$(() => {
  // foundation datepicker init
  const datepickerOptions = {
    language: document.documentElement.getAttribute("lang"),
  };

  if (datepickerOptions.language !== "en") {
    // dynamically load the translated strings in
    $.getScript(
      `/static/js/locales/foundation-datepicker.${datepickerOptions.language}.js`,
      () => {
        $("[data-name=date]").fdatepicker(datepickerOptions);
      }
    );
  } else {
    $("[data-name=date]").fdatepicker(datepickerOptions);
  }
});

$(() => {
  const needsTZSet = document.documentElement.hasAttribute("data-needs-tz-set");
  // Intl is unavailable on IE 11.
  if (needsTZSet && typeof Intl === "object") {
    const dtf = Intl.DateTimeFormat();
    if (dtf.resolvedOptions) {
      // check as no Android webview support
      const resolvedOptions = dtf.resolvedOptions();
      if (resolvedOptions.timeZone) {
        $.post("/set-time-zone", { timezone: resolvedOptions.timeZone });
      }
    }
  }
});

// allow language selection on every page
$(document).on("click", "[data-lang-selection]", (e) => {
  e.preventDefault();
  const langCode = $(e.target).data("langSelection");

  $.post("/i18n/setlang/", {
    language: langCode,
  }).always(() => {
    window.location.reload();
  });
});

// Allow for the permanent dismissal of a banner anywhere on the site
$('[data-post-callback]').on('click', (e) => {
  "use strict";
  $.post(e.currentTarget.dataset.postCallback);
});

// Modernize the Answer interface in an embedded VTS
document.querySelectorAll('.embedded-training-question-form ul#id_answer li').forEach((item) => {
  const radio = item.querySelector('input[type="radio"]');
  const submitButton = document.querySelector('.embedded-training-question-form button[type="submit"]');

  if (radio.checked) {
    item.classList.add('selected');
    submitButton.disabled = false;
  }

  if (!radio.disabled) {
    item.addEventListener('click', function () {
      document
        .querySelectorAll('.embedded-training-question-form ul#id_answer li')
        .forEach((li) => li.classList.remove('selected'));
      this.classList.add('selected');
      radio.checked = true;
      submitButton.disabled = false;
    });
  }
});

$(() => {
  // Foundation / Abide will only validate fields and apply the relevant
  // classes if you touch the field first. So if you just pass those fields
  // by, you don't get the error message a lot of the time.
  $("input").on("invalid", (e) => {
    const $form = $(e.target).parents("form");
    $form.foundation("validateForm");
  });

  $("input[type=url]").on("blur", (e) => {
    const field = $(e.target);
    let url = field.val().trim();

    // map domain names to URLs
    if (url.includes(".") && !url.match(/^https?:\/\//)) {
      url = "https://" + url;
    }

    field.val(url);
  });

  $("form.newsletter-signup__form").submit((e) => {
    e.preventDefault();
    const $el = $(e.delegateTarget);
    const form = $el[0];
    const data = new FormData(form);

    $.ajax({
      url: "/newsletter_signup/",
      type: "POST",
      data: data,
      processData: false,
      contentType: false,
    })
      .done(() => {
        const $container = $el.parent();
        $container.addClass("hide");
        $container.parent().addClass("success");
        $container.next(".display--success").removeClass("hide");
      })
      .fail((err) => {
        console.error("Error with the newsletter signup: ", err.responseText);
      });
  });
});
